import { useEffect, useCallback, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";

import honeybadger from "../honeybadger";
import queryClient from "../queryClient";
import { loginRequest, sharepointRequest } from "../auth/authConfig";

function openTokenPopup({ instance, request }) {
  return instance
    .acquireTokenPopup(request)
    .then(function (response) {
      return response.accessToken;
    })
    .catch(function (error) {
      console.warn(error);
      honeybadger.notify(error);

      clearTokenCache(instance);
      queryClient.clear();
    });
}

function clearTokenCache(instance) {
  instance.logoutRedirect({
    onRedirectNavigate: (_url) => {
      // Return false if you would like to stop navigation after local logout
      return false;
    },
  });
}

export function useSharepointToken() {
  const { instance, accounts } = useMsal();

  const getAccessToken = useCallback(() => {
    if (accounts[0]) {
      return instance
        .acquireTokenSilent({
          ...sharepointRequest,
          account: accounts[0],
        })
        .then((response) => {
          return response.accessToken;
        })
        .catch((error) => {
          console.warn(error);

          if (
            error.errorCode === "consent_required" ||
            error.errorCode === "interaction_required" ||
            error.errorCode === "login_required"
          ) {
            return openTokenPopup({ instance, request: sharepointRequest });
          } else {
            console.warn(error);
            honeybadger.notify(error);

            clearTokenCache(instance);
            queryClient.clear();
          }
        });
    } else {
      // TODO
      return Promise.resolve(null);
    }
  }, [accounts, instance]);

  return useMemo(() => ({ getAccessToken }), [getAccessToken]);
}

export function useFetchedSharepointToken() {
  const [token, setToken] = useState();
  const { getAccessToken } = useSharepointToken();

  useEffect(() => {
    getAccessToken().then((token) => {
      setToken(token);
    });
  }, [getAccessToken]);

  return token;
}

export function useGraphToken() {
  const { instance, accounts } = useMsal();

  const getAccessToken = useCallback(() => {
    if (accounts[0]) {
      return instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          return response.accessToken;
        })
        .catch((error) => {
          console.warn(error);

          if (
            error.errorCode === "consent_required" ||
            error.errorCode === "interaction_required" ||
            error.errorCode === "login_required"
          ) {
            return openTokenPopup({ instance, request: sharepointRequest });
          } else {
            console.warn(error);
            honeybadger.notify(error);

            clearTokenCache(instance);
            queryClient.clear();
          }
        });
    } else {
      return Promise.resolve(null);
    }
  }, [accounts, instance]);

  return useMemo(() => ({ getAccessToken }), [getAccessToken]);
}

export function useGraphQueryFn(queryFn, ...args) {
  const { getAccessToken } = useGraphToken();

  return useCallback(
    (extraArgs) => {
      return getAccessToken().then((accessToken) => {
        return queryFn(accessToken, ...(args || []).concat(extraArgs));
      });
    },
    [getAccessToken, queryFn, args]
  );
}

export function createReservation(accessToken, payload) {
  return post(accessToken, "reservations", payload);
}

export function createNewServiceGroup(
  accessToken,
  locationId,
  locale,
  payload
) {
  return post(
    accessToken,
    `locations/${locationId}/service_groups/?locale=${locale}`,
    payload
  );
}

export function removeServiceGroup(
  accessToken,
  serviceGroupId,
  locale,
  payload
) {
  return remove(accessToken, `service_groups/${serviceGroupId}`);
}

export function addServiceToGroup(
  accessToken,
  serviceGroupId,
  locale,
  payload
) {
  return post(
    accessToken,
    `service_groups/${serviceGroupId}/services/?locale=${locale}`,
    payload
  );
}

export function editService(accessToken, serviceId, locale, payload) {
  return patch(accessToken, `services/${serviceId}?locale=${locale}`, payload);
}

export function deleteServiceFromGroup(
  accessToken,
  serviceId,
  locale,
  payload
) {
  return remove(accessToken, `services/${serviceId}`, payload);
}

export function removeReservation(accessToken, reservationId) {
  return remove(accessToken, "reservations/" + reservationId);
}

export function getBuildings(
  accessToken,
  { objectType, locationId, usageType, designated }
) {
  var params = new URLSearchParams();
  if (objectType) {
    params.set("objectType", objectType);
  }
  if (locationId) {
    params.set("locationId", locationId);
  }
  if (usageType) {
    params.set("usageType", usageType);
  }
  if (designated) {
    params.set("designated", designated);
  }
  const url = `buildings?${params.toString()}`;
  return get(accessToken, url);
}

export function getBuildingsWithFloors(accessToken, { locationId }) {
  const url = `buildings/buildings_with_floors_data?objectType=all&locationId=${locationId}`;
  return get(accessToken, url);
}

export function getGallery(accessToken) {
  const url = `buildings/gallery`;
  return get(accessToken, url)
}

export function getLocations(accessToken, args) {
  return get(accessToken, "locations");
}

export function getDesignatedLocations(accessToken, { locationId, locale }) {
  return get(
    accessToken,
    `designated_locations?locationId=${locationId}&locale=${locale}`
  );
}

export function getReservations(accessToken) {
  return get(accessToken, "reservations");
}

export function getTodayReservationsByCapacityObjectId(
  accessToken,
  capacityObjectId,
  startTime,
  endTime
) {
  return get(
    accessToken,
    `capacity_objects/${capacityObjectId}/reservations?from=${startTime}&to=${endTime}`
  );
}

export function getBuildingFloors(
  accessToken,
  { buildingId, usageType, designatedLocation, from, to }
) {
  let url = `buildings/${buildingId}/floors?objectType=desk&usageType=${usageType}&from=${from}&to=${to}`;
  if (designatedLocation) {
    url = url + `&designated=${designatedLocation}`;
  }

  return get(accessToken, url);
}

export function getAllBuildingFloors(accessToken, buildingId) {
  let url = `buildings/${buildingId}/floors?objectType=all`;
  return get(accessToken, url);
}

export function getServices(accessToken, selectedLocation, locale) {
  return get(
    accessToken,
    `locations/${selectedLocation}/services?locale=${locale}`
  );
}

export function editServiceGroup(accessToken, serviceGroupId, payload, locale) {
  return patch(
    accessToken,
    `service_groups/${serviceGroupId}?locale=${locale}`,
    payload
  );
}

export function getGraphics(accessToken, selectedFloor) {
  return get(accessToken, `floors/${selectedFloor}/graphics`);
}

export function getAirSensors(accessToken, selectedFloor) {
  return get(accessToken, `floors/${selectedFloor}/air_sensors`);
}

export function getCapacityObjects(
  accessToken,
  selectedFloor,
  startTime,
  endTime,
  usageType,
  designLocationId
) {
  const baseUrl = `floors/${selectedFloor}/capacity_objects`;
  const params = `?includeUnreservable=true&floorId=${selectedFloor}&from=${encodeURIComponent(
    startTime
  )}&to=${encodeURIComponent(endTime)}&usageType=${usageType}&objectType=desk`;
  let url = baseUrl + params;
  if (designLocationId !== "undefined") {
    url = url + `&designated=${designLocationId}`;
  }

  if (!startTime) {
    url = baseUrl;
  }

  return get(accessToken, url);
}

export function getAllCapacityObjects(
  accessToken,
  selectedFloor,
  startTime,
  endTime
) {
  const baseUrl = `floors/${selectedFloor}/capacity_objects`;
  const params = `?includeUnreservable=true&floorId=${selectedFloor}&from=${encodeURIComponent(
    startTime
  )}&to=${encodeURIComponent(endTime)}&objectType=all`;

  let url = baseUrl + params;

  return get(accessToken, url);
}

export function getFloorTiles(accessToken, { floorId, layer, coordinates }) {
  const url =
    "floors/" +
    floorId +
    "/tiles?" +
    "layer=" +
    layer +
    "&x=" +
    coordinates.x +
    "&y=" +
    coordinates.y +
    "&z=" +
    coordinates.z;
  return getBlob(accessToken, url);
}

export function useSharepointNews({
  startRow,
  opavainsanat,
  location,
  language,
  sharepointSite,
  contentType,
  enabled = true,
}) {
  const [news, setNews] = useState();
  const { getAccessToken } = useSharepointToken();

  useEffect(() => {
    if (!enabled) {
      setNews([]);
      return;
    }
    if (!opavainsanat && !location) {
      return;
    }
    setNews(null);
    let service = axios.create();
    const baseUrl = `https://oppalveluto365.sharepoint.com/sites/${sharepointSite}/_api/search/query?`;
    const params = new URLSearchParams();
    let queryText = `ContentType:("${contentType}")`;
    if (opavainsanat) {
      queryText = `${queryText} AND opavainsanat:("${opavainsanat}")`;
    }
    if (location) {
      queryText = `${queryText} AND Korttelitoimipiste:("${location}")`;
    }
    params.append("queryText", `'${queryText}'`);
    params.append("rowLimit", "10");
    params.append("sortlist", "'Created:descending'");
    params.append("startRow", startRow);
    params.append(
      "selectProperties",
      "'Title,Description,Path,PictureThumbnailURL,OriginalPath,Created'"
    );

    getAccessToken().then((accessToken) => {
      const request = {
        method: "get",
        url: baseUrl,
        params: params,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      service(request)
        .then(({ data }) => {
          const processedRows =
            data.PrimaryQueryResult.RelevantResults.Table.Rows.map(
              ({ Cells }) => {
                // Cells is news item which is array of {"key": "Description", "Value": "Vinkki voit tykätä..."}
                // Cells is news item which is array of {"key": "Title", "Value": "Ohjeet vastaamon tietoturvaan..."}
                // Cells.forEach((cell) => {
                return {
                  title: findValueForKeyFromCell(Cells, "Title"),
                  created: findValueForKeyFromCell(Cells, "Created"),
                  message: findValueForKeyFromCell(Cells, "Description"),
                  bgImage: findValueForKeyFromCell(
                    Cells,
                    "PictureThumbnailURL"
                  ),
                  originalPath: findValueForKeyFromCell(Cells, "OriginalPath"),
                  id: findValueForKeyFromCell(Cells, "DocId"),
                };
              }
            );
          setNews(processedRows);
        })
        .catch((error) => console.log("error", error));
    });
  }, [
    getAccessToken,
    language,
    opavainsanat,
    location,
    startRow,
    sharepointSite,
    enabled,
    contentType,
  ]);

  return news;
}

const generalSharepointSite = "intra";
const generalOpavainsanat = "korttelisovellus";
export function useGeneralNews({
  startRow,
  language,
  opavainsanat,
  enabled = true,
}) {
  const contentType = language === "fi" ? "Uutinen" : "News";
  return useSharepointNews({
    startRow,
    opavainsanat: opavainsanat || generalOpavainsanat,
    language,
    sharepointSite: generalSharepointSite,
    contentType,
    enabled,
  });
}

const korttelisovellusSharepointSite = "korttelinuutiset";
export function useKorttelisovellusNews({
  startRow,
  location,
  language,
  enabled = true,
}) {
  const contentType = language === "fi" ? "Korttelin uutinen" : "Kortteli news";
  return useSharepointNews({
    startRow,
    location,
    language,
    sharepointSite: korttelisovellusSharepointSite,
    contentType,
    enabled,
  });
}

export function useLocationNews({ startRow, location, language }) {
  const generalNews = useGeneralNews({
    startRow,
    language,
    opavainsanat: "Vallilan kortteli",
    enabled: location === "vallila" || location === "Helsinki, Vallila",
  });
  const locationNews = useKorttelisovellusNews({
    startRow,
    location,
    language,
  });

  return useMemo(() => {
    if (generalNews && locationNews) {
      return generalNews.concat(locationNews).sort(function (a, b) {
        return a.created < b.created;
      });
    }
  }, [generalNews, locationNews]);
}

export async function getMe(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch("https://graph.microsoft.com/v1.0/me", options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getCheckAdminGroup(accessToken, { groupId }) {
  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  });

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      groupIds: [groupId],
    }),
  };

  return fetch("https://graph.microsoft.com/v1.0/me/checkMemberGroups", options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getMePhoto(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch("https://graph.microsoft.com/v1.0/me/photo", options)
    .then((response) => {
      if (response.status === 404) {
        return null;
      } else {
        return response.blob();
      }
    })
    .catch((error) => console.log(error));
}

function get(accessToken, url, onSuccess, onError) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl(url), options)
    .then((response) => response.json())
    .catch((error) => error.json());
}

function post(accessToken, url, payload) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl(url), options)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error.json();
    });
}

function patch(accessToken, url, payload) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl(url), options)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error.json();
    });
}

function remove(accessToken, url, payload) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const options = {
    method: "DELETE",
    headers: headers,
  };

  return fetch(apiUrl(url), options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

function getBlob(accessToken, url) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl(url), options)
    .then((response) => response.blob())
    .catch((error) => console.log(error));
}

function apiUrl(path) {
  return `${process.env.REACT_APP_API_HOST}/api/${path}`;
}

function findValueForKeyFromCell(newsItemAsArray, key) {
  let returnedValue = newsItemAsArray.find((property) => {
    return property.Key === key;
  });
  return (returnedValue && returnedValue.Value) ?? null;
}
